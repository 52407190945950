import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Review } from './review';

@Injectable()
export class BusinessReviewService {
  constructor(private http: HttpClient) {}

  getReviewsForBusiness(identifier: string): Observable<Review[]> {
    return this.http.get<{ success: boolean, reviews: Review[]}>(`/api/business/${identifier}/review`)
      .pipe(map((response) => response.reviews.map(r => ({ ...r, dateAdded: new Date(r.dateAdded)}))), catchError((err: HttpErrorResponse) => {
        return throwError(err.status === 404 ? 'The business could not be found' : 'Something went wrong')
      }));
  }

  submitReview(identifier: string, details: Review): Observable<any> {
    return this.http.post<{ success: string}>(`/api/business/${identifier}/review`, details)
      .pipe(map((response) => response.success), catchError((err: HttpErrorResponse) => {
        return throwError(err.status === 404 ? 'The business could not be found' : 'Something went wrong')
      }));
  }

}
