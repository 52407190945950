
<h3>Rate The Business</h3>

<form [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="rating-container">
    <mat-label>Rating: </mat-label>
    <app-star-rating formControlName="rating">
    </app-star-rating>
  </div>

  <mat-form-field appearance="fill">
    <mat-label>Name:</mat-label>
    <input type="text" matInput formControlName="reviewerName">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Email:</mat-label>
    <input type="email" matInput formControlName="emailAddress">
  </mat-form-field>

  <mat-form-field class="summary" appearance="fill">
    <mat-label>Summary:</mat-label>
    <input type="text" matInput formControlName="summary">
  </mat-form-field>

  <mat-form-field class="description" appearance="fill">
    <mat-label>Description:</mat-label>
    <textarea type="text" matInput formControlName="description"></textarea>
  </mat-form-field>

  <div class="action-buttons">
    <button
      [disabled]="!form.valid"
      type="submit"
      mat-flat-button color="primary">
      Submit Review
    </button>
  </div>
</form>

