import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { TokenStoreService } from 'src/app/auth/token-store.service';
import { DomainCrossoverService } from 'src/app/business/domain-crosssover.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { LoginService } from './login.service';

@Component({
  styleUrls: ['../security-styles.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent {
  errorMessage: string|null = null;

  form = this.formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  })

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private profileService: ProfileService,
    private domainCrossoverService: DomainCrossoverService,
    private tokenStore: TokenStoreService) {}

  submitForm() {
    this.errorMessage = null;
    const { email, password } = this.form.value;
    this.loginService.login(email, password)
      .pipe(first())
      .subscribe((token) => {
        this.tokenStore.storeToken(token);
        this.startAuthenticatedSession()
      },
      () => {
        this.errorMessage = 'The email address and password entered do not match. Please try again.';
      });
  }

  startAuthenticatedSession(): void {
    if (this.domainCrossoverService.crossover) {
      this.router.navigate(['/business/register']);
      return;
    }

    this.profileService.getUserBusinesses()
      .pipe(first())
      .subscribe((businesses) => {
        if (businesses.length > 0) {
          this.router.navigate(['/business/' + businesses[0].identifier + '/detail']);
        } else {
          this.router.navigate(['/business/register']);
        }
      })
  }
}
