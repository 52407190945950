import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators';

@Injectable()
export class TokenStoreService {

  constructor(private angularFireAuth: AngularFireAuth) {
    this.angularFireAuth.authState.subscribe((user) => {
      if (user) {
        user.getIdToken().then((token) => this.storeToken(token));
      } else {
        this.clearToken();
      }
    });
  }

  get isLoggedIn(): Observable<boolean> {
    return this.angularFireAuth.authState.pipe(map(m => !!m));
  }

  getToken(): string|null {
    return localStorage.getItem('bean-token');
  }

  clearToken(): void {
    localStorage.removeItem('bean-token');
  }

  storeToken(token: string): void {
    localStorage.setItem('bean-token', token);
  }
}
