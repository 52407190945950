import { Component, Output, EventEmitter } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-review-form',
  styleUrls: ['./review-form.component.scss'],
  templateUrl: './review-form.component.html',
})
export class ReviewFormComponent {
  @Output() submitReview = new EventEmitter()

  form = this.formBuilder.group({
    rating: [null, Validators.required],
    reviewerName: [null, Validators.required],
    emailAddress: [null, Validators.required],
    summary: [null, Validators.required],
    description: [null, Validators.required],
  })

  constructor(private formBuilder: FormBuilder) {
  }

  submitForm() {
    if (this.form.valid) {
      this.submitReview.emit(this.form.value)
    }
  }
}
