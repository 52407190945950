import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MapComponent } from './map/map.component';
import { StarRatingComponent } from './star-rating/star-rating.component';

@NgModule({
  declarations: [
    StarRatingComponent,
    MapComponent,
  ],
  exports: [
    StarRatingComponent,
    MapComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
  ]
})
export class CommonComponentsModule {
}
