// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyArW4jimOWmLIuYUMg6ERySwuqhdmUFU-U',
    authDomain: 'the-bean-app.firebaseapp.com',
    projectId: 'the-bean-app',
    storageBucket: 'the-bean-app.appspot.com',
    messagingSenderId: '226658226744',
    appId: '1:226658226744:web:cc5338f269aa015cfdcccd',
    measurementId: 'G-T6CD4J0FDW'
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoicGF0a2F5b25nbyIsImEiOiJja3BsajA1MXEwYmkyMnZvMTZ5dG8xbnI4In0.7-CkGB-4aqbViyt0dJLnJw',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
