import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class LoginService {
  constructor(private http: HttpClient, private angularFireAuth: AngularFireAuth) {}

  login(email: string, password: string): Observable<string> {
    const subject = new Subject<string>();
    this.angularFireAuth.signInWithEmailAndPassword(email, password)
      .then((res) => res.user?.getIdToken())
      .then((res) => {
        console.log(res);
        subject.next(res);
        subject.complete();
      })
      .catch((error) => {
        console.log(error);
        subject.error(error.code);
      });

    return subject;
  }

  logout(): Observable<unknown> {
    const subject = new Subject();
    this.angularFireAuth.signOut().then(() => {
      subject.next();
      subject.complete();
    });

    return subject;
  }
}
