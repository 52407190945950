
<button
  mat-icon-button
  *ngFor="let rating of ratings"
  (mouseover)="setHoverRating(rating)"
  (mouseleave)="clearHoverRating()"
  (click)="updateCurrentRating(rating)"
  aria-label="Example icon button with a vertical three dot icon">
  <mat-icon color="primary">{{iconForRating(rating)}}</mat-icon>
</button>
