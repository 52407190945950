import { P } from '@angular/cdk/keycodes';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ForgotPasswordService {

  constructor(private httpClient: HttpClient, private angularFireAuth: AngularFireAuth) {
  }

  initiateForgotPassword(emailAddress: string): Observable<unknown> {
    const subject = new Subject();
    this.angularFireAuth.sendPasswordResetEmail(emailAddress).then(() => {
      subject.next();
      subject.complete();
    }, error => subject.error(error));

    return subject;
    // return this.httpClient.post<{success: boolean}>('/api/forgot-password', { emailAddress});
  }

  verifyOTP(emailAddress: string, otp: string): Observable<unknown> {
    return this.httpClient.post<{success: boolean}>('/api/forgot-password', { emailAddress, otp })
    .pipe(catchError((err: HttpErrorResponse) => {
      return throwError(new Error(err?.error?.errors[0] || 'Something went wrong'));
    }));
  }

  updatePassword(emailAddress: string, otp: string, password: string): Observable<unknown> {
    return this.httpClient.post<{success: boolean}>('/api/forgot-password', { emailAddress, otp, password })
    .pipe(catchError((err: HttpErrorResponse) => {
      return throwError(new Error(err?.error?.errors[0] || 'Something went wrong'));
    }));
  }
}
