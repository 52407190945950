import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStoreService } from './auth/token-store.service';
import { Business } from './business/business';
import { ProfileService } from './profile/profile.service';
import { LoginService } from './security/login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLoggedIn: boolean;
  leftMenuOpen = false;
  myBusinessses: Business[] = [];

  constructor(
    private tokenStore: TokenStoreService,
    profileService: ProfileService,
    private router: Router,
    private loginService: LoginService) {
    this.isLoggedIn = !!tokenStore.getToken();
    this.tokenStore.isLoggedIn.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      if (this.isLoggedIn) {
        profileService.getUserBusinesses().toPromise().then((businesses) => this.myBusinessses = businesses);
      } else {
        this.myBusinessses = [];
        if (this.leftMenuOpen) {
          this.toggleLeftMenu();
        }
      }
    });
  }

  toggleLeftMenu(): void {
    this.leftMenuOpen = !this.leftMenuOpen;
  }

  logout(): void {
    this.loginService.logout().subscribe(() => {
      this.router.navigate(['/']);
    });
  }
}
