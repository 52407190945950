import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Payment } from '../business';

interface OutstandingPaymentResult {
  success: boolean;
  payment?: Payment;
  signature: string;
  billingStartDate: string;
  configuration: {
    merchantId: string
    merchantKey: string
    paymentURL: string
    passphrase: string
  };
}
@Injectable()
export class BusinessPaymentService {

  constructor(private httpClient: HttpClient) {
  }

  getOutstandingPayment(businessIdentifier: string): Observable<OutstandingPaymentResult> {
    return this.httpClient.get<OutstandingPaymentResult>(`/api/business/${businessIdentifier}/payment`);
  }
}
