import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { BusinessRoutingModule } from './business-routing.module';
import { BusinessAddressComponent } from './edit-business/business-address/business-address.component';
import { ContactNumberComponent } from './edit-business/contact-number/contact-number.component';
import { RegisterBusinessComponent } from './edit-business/edit-business.component';
import { SocialMediaProfileComponent } from './edit-business/social-media-profile/social-media-profile.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from '../auth/auth.module';
import { BusinessRegistrationService } from './business-registration.service';
import { BusinessRetrievalService } from './business-retrieval.service';
import { ViewBusinessComponent } from './view-business/view-business.component';
import { BusinessUpdateService } from './business-update.service';
import { SecurityModule } from '../security/security.module';
import { ProfileModule } from '../profile/profile.module';
import { PaymentModule } from './payments/payment.module';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { ReviewFormComponent } from './review/review-form/review-form.component';
import { BusinessReviewService } from './review/review-form/business-review.service';
import { ReviewItemComponent } from './review/review-item/review-item.component';
import { DomainsService } from './domains.service'
import { BusinessImageService } from './business-image.service';
import { ServiceTagsService } from './service-tags.service';
import { ReviewBottomSheetComponent } from './view-business/review-bottom-sheet/review-bottom-sheet.component';
import { HostingDetailsService } from './hosting/hosting-details.service';
import { HostingDetailsComponent } from './hosting/hosting-details.component';
import { DomainCrossoverService } from './domain-crosssover.service';
import { BusinessSearchService } from './business-search.service';
import { AddProductComponent } from './products/add-product.component';
import { ProductService } from './products/product.service';
import { ViewProductComponent } from './products/view-product.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
@NgModule({
  imports: [
    AuthModule,
    BusinessRoutingModule,
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatSidenavModule,
    ReactiveFormsModule,
    SecurityModule,
    ProfileModule,
    PaymentModule,
    MatBottomSheetModule,

    CommonComponentsModule,

  ],
  declarations: [
    BusinessAddressComponent,
    ContactNumberComponent,
    RegisterBusinessComponent,
    SocialMediaProfileComponent,
    ViewBusinessComponent,
    ReviewFormComponent,
    ReviewItemComponent,
    ReviewBottomSheetComponent,
    HostingDetailsComponent,

    AddProductComponent,
    ViewProductComponent,
  ],
  providers: [
    BusinessRegistrationService,
    BusinessRetrievalService,
    BusinessUpdateService,
    BusinessReviewService,
    BusinessImageService,
    DomainsService,
    HostingDetailsService,
    ProductService,
  ]
})
export class BusinessModule {
  static forRoot(): ModuleWithProviders<BusinessModule> {
    return {
      ngModule: BusinessModule,
      providers: [
        DomainCrossoverService,
        ServiceTagsService,
        BusinessSearchService,
      ]
    };
  }
}
