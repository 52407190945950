import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class BusinessRegistrationService {
  constructor(private http: HttpClient) {}

  registerBusiness(details: any): Observable<string> {

    return this.http.post<{ success: string}>('/api/business', details)
      .pipe(map((response) => response.success), catchError((err: HttpErrorResponse) => {
        return throwError(new Error(err?.error?.errors[0] || 'Something went wrong'));
      }));
  }
}
