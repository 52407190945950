<header>
  <h1>Find a business on The Bean App</h1>
</header>

<main>
  <mat-card>
    <form class="search-form" [formGroup]="searchForm">
      <div class="form-fields">
        <mat-form-field class="service-form-field" floatLabel="always">
          <mat-label>Service</mat-label>
          <select matNativeControl formControlName="service">
            <option value="">Select</option>
            <option *ngFor="let serviceTag of serviceTags" [value]="serviceTag.name">
              {{serviceTag.name}}
            </option>
          </select>
        </mat-form-field>
        <mat-form-field class="name-form-field" floatLabel="always">
          <mat-label>Search by Business Name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </div>
      <div class="actions">
        <button [disabled]="!searchForm.valid" mat-flat-button color="accent" (click)="searchForBusiness()">Search</button>
        <button mat-flat-button color="accent" (click)="searchForBusinessNearMe()">Search near me</button>
      </div>
    </form>
  </mat-card>

  <section *ngIf="searchResults">
  <h2>Search Results</h2>
    <mat-card class="search-results">
      <p *ngIf="!searchResults.length">
        No businesses on the Bean App matched the search criteria
      </p>
      <div class="search-result-item" *ngFor="let result of searchResults" [routerLink]="'/business/' + result.identifier + '/detail'">
        <mat-icon>store</mat-icon>
        <div>
          <span class="business-name">{{result.name}}</span>
          <span class="business-description">{{result.description}}</span>
        </div>
      </div>
    </mat-card>
  </section>
</main>
