import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TokenStoreService } from "src/app/auth/token-store.service";
import { DomainCrossoverService } from "src/app/business/domain-crosssover.service";
import { DomainCheckStatus, DomainsService } from "src/app/business/domains.service";

@Component({
  styleUrls: ['./domain-search.component.scss'],
  templateUrl: './domain-search.component.html',
})
export class DomainSearchComponent {
  domainCheckResult?: DomainCheckStatus | null
  loading = false
  form = this.formBuilder.group({
    secondLevelDomain: [null, Validators.required]
  })

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private domainCrossoverService: DomainCrossoverService,
    private tokenService: TokenStoreService,
    private domainService: DomainsService) {
  }

  handleSearch(): void {
    this.domainCheckResult = null;
    this.loading = true
    this.domainService.getDomainAvailability(this.form.controls.secondLevelDomain.value, 'co.za')
      .subscribe((result) => {
        this.loading = false;
        this.domainCheckResult = result;
      });
  }

  handleRegisterBuisness(): void {
    if (!this.domainCheckResult) {
      return;
    }

    this.domainCrossoverService.crossover = {
      domain: {
        secondLevelDomain: this.form.controls.secondLevelDomain.value,
        topLevelDomain: 'co.za',
      },
      domainCheckStatus: this.domainCheckResult
    };

    if (this.tokenService.getToken()) {
      this.router.navigate(['/business/register']);
    } else {
      this.router.navigate(['/register']);
    }
  }
}
