<header>
  <h1>Payment</h1>
</header>
<main>
  <mat-card>
    <p>You are about to make a payment of R {{outstandingPayment?.amount}} </p>

    <form [action]="paymentURL" method="post">
      <input type="hidden" name="merchant_id" [value]="merchantId">
      <input type="hidden" name="merchant_key" [value]="merchantKey">
      <input type="hidden" name="return_url" [value]="successURL">
      <input type="hidden" name="cancel_url" [value]="cancelURL">
      <input type="hidden" name="notify_url" [value]="notifyURL">

      <input type="hidden" name="amount" [value]="outstandingPayment?.amount">
      <input type="hidden" name="item_name" [value]="outstandingPayment?.description">
      <input type="hidden" name="item_description" [value]="outstandingPayment?.description">
      <input type="hidden" name="subscription_type" value="1">
      <input type="hidden" name="billing_date" [value]="billingStartDate">
      <input type="hidden" name="recurring_amount" value="90">
      <input type="hidden" name="frequency" value="3">
      <input type="hidden" name="cycles" value="0">

      <input type="hidden" name="signature" [value]="signature">
      <button mat-flat-button color="primary"  type="submit">Continue</button>
    </form>
  </mat-card>
</main>

