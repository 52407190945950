import { Component, Input } from '@angular/core'
import { Review } from '../review-form/review'

@Component({
  selector: 'app-review-item',
  styleUrls: ['./review-item.component.scss'],
  templateUrl: './review-item.component.html',
})
export class ReviewItemComponent {
  @Input() review?: Review
}
