import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ForgotPasswordService } from './forgot-password.service';

@Component({
  styleUrls: ['./forgot-password.component.scss'],
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {
  form = this.formBuilder.group({
    emailAddress: [null, Validators.required],
    otp: [null],
    password: []
  });

  forgotPasswordInitiated = false;
  otpVerified = false;
  errorMessage?: string | null;

  constructor(
    private formBuilder: FormBuilder,
    private forgotPasswordService: ForgotPasswordService,
    private router: Router) {
  }

  initiateOTP(): void {
    const { emailAddress } = this.form.getRawValue();
    this.errorMessage = null;
    this.forgotPasswordService.initiateForgotPassword(emailAddress)
      .subscribe(() => {
        this.forgotPasswordInitiated = true;
        this.form.controls.emailAddress.disable();
        this.form.controls.otp.setValidators(Validators.required);
      });
  }

  verifyOTP(): void {
    const { emailAddress, otp } = this.form.getRawValue();
    if (!emailAddress || !otp) {
      return;
    }

    this.errorMessage = null;
    this.forgotPasswordService.verifyOTP(emailAddress, otp)
      .subscribe(() => {
        this.otpVerified = true;
        this.form.controls.otp.disable();
        this.form.controls.password.setValidators(Validators.required);
      }, error => {
        if (error.message === 'INVALID_OTP') {
          this.errorMessage = 'The verification code you entered is not valid';
        } else if (error.message === 'EXPIRED_OTP') {
          this.errorMessage = 'The verification code you entered has expired.';
        } else {
          this.errorMessage = error;
        }
      });
  }

  updatePassword(): void {
    const { emailAddress, otp, password } = this.form.getRawValue();
    if (!emailAddress || !otp || !password) {
      return;
    }

    this.errorMessage = null;
    this.forgotPasswordService.updatePassword(emailAddress, otp, password)
      .subscribe(() => {
        this.otpVerified = true;
        this.router.navigate(['/login']);
      }, error => {
        if (error.message === 'INVALID_OTP') {
          this.errorMessage = 'The verification code you entered is not valid';
        } else {
          this.errorMessage = error;
        }
      });
  }
}
