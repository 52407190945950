import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { TokenStoreService } from 'src/app/auth/token-store.service';
import { ProfileModule } from 'src/app/profile/profile.module';
import { ProfileService } from 'src/app/profile/profile.service';
import { Business, BusinessImageType, BusinessStatus, ImageDetail, ServiceTag } from '../business';
import { BusinessImageService } from '../business-image.service';
import { BusinessRetrievalService } from '../business-retrieval.service';
import { BusinessReviewService } from '../review/review-form/business-review.service';
import { Review } from '../review/review-form/review';
import { v4 as uuidv4 } from 'uuid';
import { ServiceTagsService } from '../service-tags.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ReviewBottomSheetComponent } from './review-bottom-sheet/review-bottom-sheet.component';
import { Title, Meta } from '@angular/platform-browser';
import { ProductService } from '../products/product.service';
import { Product } from '../products/product';

interface ImageViewDetail extends ImageDetail {
  source: string;
}
@Component({
  styleUrls: ['./view-business.component.scss'],
  templateUrl: './view-business.component.html',
})
export class ViewBusinessComponent implements OnInit, OnDestroy {
  business?: Business;
  isUsersBusiness = false;
  reviews: Review[] = [];
  products: Product[] = [];
  images: ImageViewDetail[] = [];
  logoImage: ImageViewDetail | null = null;
  currentImageIndex: number | null = null;
  services: ServiceTag[] = [];
  reviewsVisible = false;
  leftMenuOpen = false;

  get hostingRegistrationRequired(): boolean {
    return this.business?.status === BusinessStatus.AWAITING_HOSTING_REGISTRATION;
  }

  constructor(
    private bottomSheet: MatBottomSheet,
    private titleService: Title,
    private metaService: Meta,
    private businessRetrievalService: BusinessRetrievalService,
    private businessReviewService: BusinessReviewService,
    private businessImageService: BusinessImageService,
    private profileService: ProfileService,
    private tokenStore: TokenStoreService,
    private activatedRoute: ActivatedRoute,
    private serviceTagService: ServiceTagsService,
    private productService: ProductService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      this.business = await this.businessRetrievalService.retrieveBusiness(params.identifier).toPromise();
      this.refreshReviews();
      this.refreshImages();
      this.refreshProducts();
      this.loadServiceTags();
      this.titleService.setTitle(this.business?.name);
      this.metaService.updateTag({
        name: 'description',
        content: this.business?.description,
      });

      if (this.tokenStore.getToken()) {
        this.profileService.getUserBusinesses()
          .pipe(first())
          .subscribe((businesses) => {
            this.isUsersBusiness = !!businesses.filter(f => f.identifier === params.identifier).length;
          });
      }
    });
  }



  ngOnDestroy(): void {
    this.titleService.setTitle('The Bean App');
    this.metaService.updateTag({
      name: 'description',
      content: 'The Bean App aims to digitise small businesses, helping potential clients and customers find them.',
    });
  }

  toggleLeftMenu(): void {
    this.leftMenuOpen = !this.leftMenuOpen;
  }

  scrollToElement(id: string): void {
    const el = document.getElementById(id);
    el?.scrollIntoView({behavior: 'smooth'});
    this.leftMenuOpen = false;
  }

  moveToNextGalleryImage(): void {
    this.currentImageIndex = this.currentImageIndex!! + 1;
  }

  moveToPreviousImage(): void {
    this.currentImageIndex = this.currentImageIndex!! - 1;
  }

  refreshReviews(): void {
    this.businessReviewService.getReviewsForBusiness(this.business!!.identifier)
      .pipe(first())
      .subscribe((reviews) => {
        this.reviews = reviews;
      });
  }

  refreshProducts(): void {
    if (this.business) {
      this.productService.getProductsForBusiness(this.business.identifier)
          .pipe(first())
          .subscribe(products => {
            this.products = products;
          });
    }
  }

  submitReview(review: Review): void {
    this.businessReviewService.submitReview(this.business!!.identifier, review)
      .pipe(first())
      .subscribe(() => {
        this.refreshReviews();
      });
  }

  refreshImages(): void {
    this.businessImageService.getImagesForBusiness(this.business!!.identifier)
      .subscribe((images) => {
        const imagesBaseURL = `${window.location.origin}/api/business/${this.business!!.identifier}/images/`;
        const imagesWithSource = images.map((image) => {
          return {
            ...image,
            source: imagesBaseURL + image.filename
          };
        });

        this.images = imagesWithSource.filter(i => i.imageType === BusinessImageType.GALLERY);
        this.logoImage = imagesWithSource.filter(i => i.imageType === BusinessImageType.LOGO)[0];

        if (images.length) {
          this.currentImageIndex = this.currentImageIndex || 0;
        } else {
          this.currentImageIndex = null;
        }
      });
  }

  loadServiceTags() {
    this.serviceTagService.getServiceTagsForBusiness(this.business!!.identifier)
      .subscribe(services => {
        this.services = services;
      });
  }

  handleLogoFileUploaded(event: any) {
    this.handlePictureUploaded(event, BusinessImageType.LOGO, this.logoImage?.filename);
  }

  handleGalleryFileUploaded(event: any) {
    this.handlePictureUploaded(event, BusinessImageType.GALLERY);
  }

  handlePictureUploaded(event: any, imageType: BusinessImageType, filename?: string) {
    const file: File = event.target.files[0];
    if (!file) {
      return;
    }

    let filenameToUse;
    if (filename) {
      filenameToUse = filename;
    } else {
      const extensionRegex = /(?:\.([^.]+))?$/;
      const extension = extensionRegex.exec(file.name)!![0];
      const randomIdentifier = uuidv4();
      filenameToUse = randomIdentifier + extension;
    }

    this.businessImageService.uploadImageForBusiness(this.business!!.identifier, filenameToUse, imageType, file)
      .subscribe(() => {
        this.refreshImages();
      });
  }

  handleReviewClick() {
    const bottomSheetRef = this.bottomSheet.open(ReviewBottomSheetComponent);
    bottomSheetRef.afterDismissed().subscribe(result => {
      if (result) {
        this.submitReview(result);
      }
    });
  }
}
