import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { first } from 'rxjs/operators';
import { TokenStoreService } from 'src/app/auth/token-store.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { Product } from './product';
import { ProductService } from './product.service';

@Component({
  template: `
    <header><h1>{{this.existingProduct?.name}}</h1></header>

    <main>
      <a [routerLink]="'/business/' + businessIdentifier + '/detail'">Back</a>
      <mat-card>
        <div class="product-information">

          <div class="images">
            <div class="main-image">
              <div class="no-images-container" *ngIf="images.length === 0 && isUsersBusiness">
                There are currently no images for the business. Click on the button below to add pictures for the business.
              </div>

              <div *ngIf="currentImageIndex != null" class="image-change-container left">
                <button mat-icon-button
                  (click)="moveToPreviousImage()"
                  *ngIf="currentImageIndex > 0 && images.length > 1">
                  <mat-icon>chevron_left</mat-icon>
                </button>
              </div>
              <div  *ngIf="currentImageIndex != null" class="current-image-container">
                <img [src]="images[currentImageIndex].source" >
              </div>
              <div *ngIf="currentImageIndex != null" class="image-change-container right">
                <button mat-icon-button
                  (click)="moveToNextGalleryImage()"
                  *ngIf="currentImageIndex < (images.length - 1)">
                  <mat-icon>chevron_right</mat-icon>
                </button>
              </div>
            </div>
            <div class="image-list-container">
              <input accept="image/*" class="file-upload" type="file" #imageInput (change)="handlePictureUploaded($event)">
              <div class="thumbnails">
                <img *ngFor="let image of images; let i = index" [src]="image.source" (click)="currentImageIndex = i" />
                <button class="add-gallery-image" *ngIf="isUsersBusiness"  mat-icon-button color="primary" (click)="imageInput.click()">
                  <mat-icon>add_to_photos</mat-icon>
                </button>
              </div>
              <div class="actions" *ngIf="isUsersBusiness">
                <button class="add-gallery-image" *ngIf="isUsersBusiness"  mat-icon-button color="primary" (click)="imageInput.click()">
                  <mat-icon>add_to_photos</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="product-details">
            <h3>R {{existingProduct?.price }}</h3>
            {{existingProduct?.description}}
          </div>
        </div>
      </mat-card>
    </main>
  `,
  styleUrls: ['./view-product.component.scss'],
})
export class ViewProductComponent implements OnInit {
  isUsersBusiness = false;
  businessIdentifier?: string;
  productIdentifier?: string;
  existingProduct?: Product|null;
  currentImageIndex: number | null = null;
  formGroup = this.formBuilder.group({
    name: ['', Validators.required],
    description: [null, Validators.required]
  });
  images: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private tokenStore: TokenStoreService,
    private profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.businessIdentifier = params.businessIdentifier;
      this.productIdentifier = params.productIdentifier;
      this.refreshImages();

      if (this.businessIdentifier && this.productIdentifier) {
        this.productService.getProduct(this.businessIdentifier, this.productIdentifier)
          .pipe(first())
          .subscribe(product => {
            this.existingProduct = product;
            this.formGroup.patchValue({...product});
          });
      }

      if (this.businessIdentifier) {
        if (this.tokenStore.getToken()) {
          this.profileService.getUserBusinesses()
            .pipe(first())
            .subscribe((businesses) => {
              this.isUsersBusiness = !!businesses.filter(f => f.identifier === this.businessIdentifier).length;
            });
        }
      }
    });
  }

  moveToNextGalleryImage(): void {
    this.currentImageIndex = this.currentImageIndex!! + 1;
  }

  moveToPreviousImage(): void {
    this.currentImageIndex = this.currentImageIndex!! - 1;
  }

  refreshImages(): void {
    if (this.businessIdentifier && this.productIdentifier) {
      this.productService.getImagesForProduct(this.businessIdentifier, this.productIdentifier)
        .subscribe((images) => {
          const imagesBaseURL = `${window.location.origin}/api/business/${this.businessIdentifier}/product/${this.productIdentifier}/images/`;
          const imagesWithSource = images.map((image) => {
            return {
              ...image,
              source: imagesBaseURL + image.filename
            };
          });

          this.images = imagesWithSource;
          if (images.length) {
            this.currentImageIndex = this.currentImageIndex || 0;
          } else {
            this.currentImageIndex = null;
          }
        });
    }
  }

  handlePictureUploaded(event: any, filename?: string): void {
    const file: File = event.target.files[0];
    if (!file) {
      return;
    }

    let filenameToUse;
    if (filename) {
      filenameToUse = filename;
    } else {
      const extensionRegex = /(?:\.([^.]+))?$/;
      const extension = extensionRegex.exec(file.name)!![0];
      const randomIdentifier = uuidv4();
      filenameToUse = randomIdentifier + extension;
    }

    if (this.businessIdentifier && this.productIdentifier) {
      this.productService.uploadImage(this.businessIdentifier, this.productIdentifier, filenameToUse, file)
        .subscribe(() => {
          this.refreshImages();
        });
    }
  }
}
