<div class="container">
  <img class="decorative-images left" src="assets/img/man-using-mobile-phone.svg">
  <main>
    <mat-card class="content" >
      <div class="logo-container">
        <img class="logo" src="assets/img/logo.jpg" >
      </div>
      <h2>Login to start using the Bean App</h2>
      <p class="error-message" *ngIf="errorMessage">{{errorMessage}}</p>

      <form [formGroup]="form" (submit)="submitForm()">
        <mat-form-field appearance="fill">
          <mat-label>Email address:</mat-label>
          <input formControlName="email" matInput>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Password:</mat-label>
          <input formControlName="password" matInput type="password">
        </mat-form-field>

        <div>
          <button [disabled]="!form.valid" mat-raised-button color="primary">Login</button>
        </div>
        <div class="secondary-actions">
          <a [routerLink]="'/forgot-password'">Forgotten Password</a>
          <span> | </span>
          <a [routerLink]="'/register'">Sign Up</a>
        </div>
      </form>
    </mat-card>
  </main>
</div>
