import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth-guard';
import { TokenStoreService } from './token-store.service'
import { TokenInterceptor } from './token.interceptor';

@NgModule({
  providers: [
    TokenStoreService,
    TokenInterceptor,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    }
  ],
})
export class AuthModule {
}
