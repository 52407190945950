import { Component, forwardRef, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { SocialProfile, SocialProfileType } from '../../business';

@Component({
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SocialMediaProfileComponent), multi: true},
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => SocialMediaProfileComponent), multi: true},
  ],
  selector: 'app-social-media-profile',
  styleUrls: ['./social-media-profile.component.scss'],
  templateUrl: './social-media-profile.component.html',
})
export class SocialMediaProfileComponent implements ControlValueAccessor, Validator, OnInit {

  form = this.formBuilder.group({
    type: [null, Validators.required],
    handle: [null, Validators.required],
    path: [null, Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.form.controls.type.valueChanges.subscribe((type) => {
      if (type === SocialProfileType.FACEBOOK) {
        this.form.controls.path.setValidators(Validators.required);
        this.form.controls.handle.clearValidators();
      } else {
        this.form.controls.handle.setValidators(Validators.required);
        this.form.controls.path.clearValidators();
      }
    })
  }

  writeValue(obj?: SocialProfile): void {
    const { type, path } = obj || {};
    let handle: string|null = null;
    if (path?.length) {
      if (type === SocialProfileType.TWITTER && path.indexOf('twitter.com') > -1) {
        const patternMatch = path.match(/twitter\.com\/(.*)/);
        handle = patternMatch ? patternMatch[1]?.replace('/', '') : null;
      } else if (type === SocialProfileType.INSTAGRAM && path.indexOf('instagram.com') > -1) {
        const patternMatch = path.match(/instagram\.com\/(.*)/);
        handle = patternMatch ? patternMatch[1]?.replace('/', '') : null;
      }
    }

    this.form.patchValue({ type, path, handle });
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(({ path, type, handle }) => {
      if (type === SocialProfileType.TWITTER && handle?.length) {
        path = 'https://twitter.com/' + handle;
      } else if (type === SocialProfileType.INSTAGRAM && handle?.length) {
        path = 'https://www.instagram.com/' + handle;
      }

      fn({ path, type })
    });
  }

  registerOnTouched(fn: any): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return {
      invalidSocialProfile: 'The social profile is not valid',
    };
  }

  getSocialDescription(): string {
    const type = this.form.controls.type.value as string;
    const descriptions: { [key: string]: string } = {
      FACEBOOK: 'Facebook Page Address',
      INSTAGRAM: 'Instagram Handle',
      TWITTER: 'Twitter Handle'
    };

    return descriptions[type];
  }

}
