<div class="container">
  <img class="decorative-images left" src="assets/img/woman-using-mobile-phone.svg">
  <main>
    <mat-card class="content" >
      <div class="logo-container">
        <img class="logo" src="assets/img/logo.jpg" >
      </div>
      <h2>Register to start using the Bean App</h2>
      <p class="error-message" *ngIf="errorMessage">{{errorMessage}}</p>

      <form [formGroup]="form" (submit)="submitForm()">
        <mat-form-field appearance="fill">
          <mat-label>First name:</mat-label>
          <input formControlName="firstName" matInput>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Last name:</mat-label>
          <input formControlName="lastName" matInput>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Email address:</mat-label>
          <input formControlName="email" matInput>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Phone number:</mat-label>
          <input formControlName="phoneNumber" matInput>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Password:</mat-label>
          <input formControlName="password" matInput [type]="hidePassword ? 'password' : 'text'" >
          <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Confirm password:</mat-label>
          <input formControlName="confirmPassword" matInput [type]="hideConfirmPassword ? 'password' : 'text'">
          <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword">
            <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

        <div>
          <button [disabled]="!form.valid" mat-raised-button color="primary">Register</button>
        </div>
        <div>
          Already have an account? <a [routerLink]="'/login'">Login</a>
        </div>
      </form>
    </mat-card>
  </main>
</div>
