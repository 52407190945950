import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegistrationService } from './registration.service';

@Component({
  styleUrls: ['../security-styles.scss'],
  templateUrl: './account-register.component.html',
})
export class AccountRegisterComponent {
  errorMessage?:string|null;
  hidePassword = true
  hideConfirmPassword = true

  form = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', Validators.required],
    phoneNumber: ['', Validators.required],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
  })

  constructor(
    private router: Router,
    private registrationService: RegistrationService,
    private formBuilder: FormBuilder) {}

  submitForm() {
    this.errorMessage = null;
    this.registrationService.registerUser(this.form.value)
      .subscribe(
        () => this.router.navigate(['/login']),
        (error) => {
          if (error?.message === 'ACCOUNT_EXISTS') {
            this.errorMessage = 'The email address is already registerd. Please login.';
          }
        });
  }
}
