<header>
  <h1>Forgot Password</h1>
</header>

<main>
  <mat-card>
    <p *ngIf="!forgotPasswordInitiated">To reset your password, enter your email address below:</p>

    <p class="error-message" *ngIf="errorMessage">{{errorMessage}}</p>
    <form [formGroup]="form">
      <mat-form-field>
        <mat-label>Email address</mat-label>
        <input type="email" formControlName="emailAddress" matInput required>
      </mat-form-field>

      <p *ngIf="forgotPasswordInitiated">
        An email has been sent to the specified email address. Follow the link in the email to change your address.
      </p>

      <div class="action-buttons">
        <button mat-flat-button color="primary" *ngIf="!forgotPasswordInitiated" (click)="initiateOTP()">Submit</button>
      </div>
    </form>
  </mat-card>
</main>
