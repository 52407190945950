
<mat-toolbar class="mat-elevation-z2">
  <button *ngIf="isLoggedIn" mat-icon-button class="left-menu-button" aria-label="Menu button" (click)="toggleLeftMenu()">
    <mat-icon>menu</mat-icon>
  </button>

  <a class="toolbar-title" [routerLink]="'/'">
    <img class="toolbar-logo" src="assets/img/logo.png" >
    The Bean App
  </a>

  <button mat-button color="accent" class="toolbar-nav-button" [routerLink]="'/about-us'">
    About Us
  </button>

  <button mat-button color="accent" class="toolbar-nav-button"  [routerLink]="'/search'">
    Business Search
  </button>

  <button mat-button color="accent" class="toolbar-nav-button" [routerLink]="'/government-portal'">
    Government Portal
  </button>

  <button mat-button color="accent" class="toolbar-nav-button" [routerLink]="'/large-enterprise-portal'">
    Large Enterprise Portal
  </button>

  <span class="spacer"></span>

  <button class="register-button toolbar-nav-button" *ngIf="!isLoggedIn" mat-flat-button color="primary" [routerLink]="'/register'">
    Register Your Business
  </button>


  <button class="login-button toolbar-nav-button" color="accent" *ngIf="!isLoggedIn" mat-flat-button [routerLink]="'/login'">
    Login
  </button>

  <button class="logout-button toolbar-nav-button"  *ngIf="isLoggedIn" mat-button (click)="logout()">
    Logout
  </button>

  <button mat-icon-button class="mobile-menu-button" aria-label="Menu button" [matMenuTriggerFor]="mobileMenu">
    <mat-icon>menu</mat-icon>
  </button>

  <mat-menu #mobileMenu="matMenu">
    <button mat-menu-item [routerLink]="'/about-us'">About Us</button>
    <button mat-menu-item [routerLink]="'/search'">Business Search</button>
    <button mat-menu-item [routerLink]="'/government-portal'">Government Portal</button>
    <button mat-menu-item [routerLink]="'/large-enterprise-portal'">Large Enterprise Portal</button>
    <mat-divider></mat-divider>
    <button mat-menu-item *ngIf="!isLoggedIn" [routerLink]="'/register'">Register Your Business</button>
    <button mat-menu-item *ngIf="!isLoggedIn" [routerLink]="'/login'">Login</button>

    <button mat-menu-item *ngIf="isLoggedIn && myBusinessses.length > 0" [matMenuTriggerFor]="registeredBusinessMenu">
      My Businesses
    </button>

    <button mat-menu-item *ngIf="isLoggedIn" [routerLink]="'/business/register'">Register New Business</button>


    <button mat-menu-item *ngIf="isLoggedIn" (click)="logout()">Logout</button>
  </mat-menu>
</mat-toolbar>

<mat-menu #registeredBusinessMenu="matMenu">
  <button mat-menu-item *ngFor="let business of myBusinessses" [routerLink]="'/business/' + business.identifier + '/detail'">
    {{business.name}}
  </button>
</mat-menu>

<mat-sidenav-container>
  <mat-sidenav mode="side" [opened]="leftMenuOpen">
    <ul class="side-navigation-list">

      <li *ngIf="myBusinessses.length > 0" class="side-navigation-list-item">
        <mat-icon>store</mat-icon>
        <a [matMenuTriggerFor]="registeredBusinessMenu">My Businesses</a>
      </li>

      <li class="side-navigation-list-item">
        <mat-icon>add_circle</mat-icon>
        <a [routerLink]="'/business/register'">Register New Business</a>
      </li>
    </ul>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet>
    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


