import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceTag } from './business';

@Injectable({ providedIn: 'root' })
export class ServiceTagsService {
  constructor(private httpClient: HttpClient) {
  }

  getAllServiceTags(): Observable<ServiceTag[]> {
    return this.httpClient.get<{ success: boolean, tags: ServiceTag[]}>('/api/service-tag')
      .pipe(map((response) => response.tags));
  }

  getServiceTagsForBusiness(businessIdentifier: string): Observable<ServiceTag[]> {
    return this.httpClient.get<{ success: boolean, tags: ServiceTag[]}>(`/api/business/${businessIdentifier}/service-tag`)
      .pipe(map((response) => response.tags));
  }

  createServiceTagForBusiness(businessIdentifier: string, serviceTag: ServiceTag): Observable<unknown> {
    return this.httpClient.post<{ success: boolean }>(`/api/business/${businessIdentifier}/service-tag`, serviceTag)
      .pipe(map((response) => response.success));
  }

  deleteServiceTagForBusiness(businessIdentifier: string, serviceTag: ServiceTag): Observable<unknown> {
    return this.httpClient.delete<{ success: boolean }>(`/api/business/${businessIdentifier}/service-tag/${encodeURIComponent(serviceTag.name)}`)
      .pipe(map((response) => response.success));
  }
}
