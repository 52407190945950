import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon'

import { AccountRegisterComponent } from './register/account-register.component';
import { RegistrationService } from './register/registration.service';
import { LoginService } from './login/login.service';
import { LoginComponent } from './login/login.component';
import { AuthModule } from '../auth/auth.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { ProfileModule } from '../profile/profile.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordService } from './forgot-password/forgot-password.service';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    HttpClientModule,
    ReactiveFormsModule,
    AuthModule,
    RouterModule,
    ProfileModule,
    AngularFireAuthModule,
  ],
  declarations: [
    AccountRegisterComponent,
    LoginComponent,
    ForgotPasswordComponent,
  ],
  providers: [
    LoginService,
    RegistrationService,
    ForgotPasswordService,
  ]
})
export class SecurityModule {}
