<header>
  <h1>{{identifier ? 'Update Business' : 'Register New Business' }}</h1>
</header>

<main>
  <mat-card>
    <form class="register-business-form" [formGroup]="form">
      <mat-form-field class="form-field">
        <mat-label>Business Name</mat-label>
        <input matInput formControlName="name" >
      </mat-form-field>

      <div class="domain-container">
        <mat-form-field class="form-field">
          <mat-label>Domain</mat-label>
          <input matInput placeholder="my-business" formControlName="domain">
          <span matSuffix class="top-level-domain">.co.za</span>
          <mat-error *ngIf="form.controls.domain.errors?.domainUnavailable">The domain is not available</mat-error>
          <mat-hint *ngIf="domainCheckStatus === 'AVAILABLE'">The domain is available</mat-hint>
        </mat-form-field>

        <div class="availability-container" *ngIf="form.controls.domain.enabled">
          <button
            mat-flat-button
            color="primary"
            *ngIf="!domainCheckStatus"
            (click)="checkForDomainAvailability()"
            [disabled]="!form.controls.domain.value || !form.controls.domain.value.length || checkingAvailability">
            Check Availability
          </button>

          <mat-icon class="success-circle" *ngIf="domainCheckStatus === 'AVAILABLE'">check_circle</mat-icon>
          <mat-icon class="error-circle" *ngIf="domainCheckStatus === 'UNAVAILABLE'">error</mat-icon>
        </div>

      </div>

      <mat-form-field class="form-field">

        <mat-label>Description</mat-label>
        <textarea matInput placeholder="Ex. We are a business that..." formControlName="description"></textarea>
      </mat-form-field>


      <mat-form-field class="form-field">
        <mat-label>Services</mat-label>
        <mat-chip-list #servicesChipList aria-label="Fruit selection">
          <mat-chip
            *ngFor="let service of businessServices"
            [selectable]="false"
            [removable]="true"
            (removed)="removeService(service)">
            {{service.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            #servicesInput
            [matAutocomplete]="servicesAutocomplete"
            [matChipInputFor]="servicesChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="handleChipAddValue($event)">
        </mat-chip-list>
        <mat-autocomplete #servicesAutocomplete="matAutocomplete" (optionSelected)="serviceSelected($event)">
          <mat-option *ngFor="let option of allServices" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
        <mat-hint>Add a list of services you offer separated by a comma</mat-hint>
      </mat-form-field>

      <section formArrayName="addresses">
        <h2>Business Address</h2>

        <app-edit-business-address *ngFor="let address of addresses.controls; let i = index" [formControlName]="i" [addressNumber]="i + 1">
        </app-edit-business-address>

      </section>

      <section formArrayName="contactNumbers">
        <h2>Contact Number</h2>

        <app-business-contact-number *ngFor="let contactNumber of contactNumbers.controls; let i = index" [formControlName]="i" >
        </app-business-contact-number>

      </section>

      <section formArrayName="socialProfiles">
        <h2>Social Media Profiles</h2>

        <div>
          <mat-checkbox [checked]="hasSocialProfile('FACEBOOK')" (change)="toggleSocialProfile('FACEBOOK')">Facebook</mat-checkbox>
          <mat-checkbox [checked]="hasSocialProfile('INSTAGRAM')" (change)="toggleSocialProfile('INSTAGRAM')">Instagram</mat-checkbox>
          <mat-checkbox [checked]="hasSocialProfile('TWITTER')" (change)="toggleSocialProfile('TWITTER')">Twitter</mat-checkbox>
        </div>

        <app-social-media-profile *ngFor="let socialProfile of socialProfiles.controls; let i = index" [formControlName]="i" >
        </app-social-media-profile>

      </section>


      <div>
        <button mat-flat-button color="primary" [disabled]="!form.valid" (click)="submitForm()">
          Save
        </button>
      </div>

    </form>
  </mat-card>
</main>



