import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export enum DomainCheckStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE'
}

@Injectable()
export class DomainsService {
  constructor(private httpClient: HttpClient) {
  }

  getDomainAvailability(secondLevelDomain: string, topLevelDomain: string): Observable<DomainCheckStatus> {
    const params = {
      tld: topLevelDomain,
      sld: secondLevelDomain,
    }
    return this.httpClient.get<{ success: boolean, status: DomainCheckStatus}>('/api/domain/availability', { params })
      .pipe(map((response) => response.status), catchError((err: HttpErrorResponse) => {
        if (err.status === 409) {
          return of(DomainCheckStatus.UNAVAILABLE as DomainCheckStatus)
        }

        return throwError(err)
      }));
  }
}
