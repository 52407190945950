import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './information/about-us/about-us.component';
import { DomainSearchComponent } from './information/domain-search/domain-search.component';
import { SearchComponent } from './search/search.component';
import { ForgotPasswordComponent } from './security/forgot-password/forgot-password.component';
import { LoginComponent } from './security/login/login.component';
import { AccountRegisterComponent } from './security/register/account-register.component';

const routes: Routes = [
  {
    path: 'register',
    component: AccountRegisterComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'business',
    loadChildren: () => import('./business/business.module').then(m => m.BusinessModule)
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'domain-search',
    component: DomainSearchComponent,
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'government-portal',
    component: ComingSoonComponent,
  },
  {
    path: 'large-enterprise-portal',
    component: ComingSoonComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
