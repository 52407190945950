import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Business } from './business';

@Injectable()
export class BusinessUpdateService {
  constructor(private http: HttpClient) {}

  updateBusiness(identifier: string, details: Business): Observable<string> {
    return this.http.put<{ success: string}>('/api/business/' + identifier, details)
      .pipe(map((response) => response.success), catchError((err: HttpErrorResponse) => {
        return throwError(err.status === 404 ? 'The business could not be found' : 'Something went wrong')
      }));
  }
}
