<header>
  <h1>Domain Search</h1>
</header>

<main>
  <mat-card>
    <p>A domain name is your online identity for your business. Search to see if whether your business name, or desired business name, is available under .CO.ZA. If not available try another domain name that best describes your business.</p>
    <form [formGroup]="form" (ngSubmit)="handleSearch()">
      <div class="input-container">
        <mat-form-field appearance="outline">
          <mat-label>Domain Name</mat-label>
          <input matInput formControlName="secondLevelDomain">
          <span matSuffix>.co.za</span>
        </mat-form-field>
        <button mat-flat-button color="primary" [disabled]="loading">
          Search
        </button>
      </div>
    </form>
    <div>
      <div *ngIf="domainCheckResult === 'AVAILABLE'" class="domain-result-container">
        <p class="domain-result-message domain-available">
          <mat-icon>check_circle</mat-icon>
          <span>The domain name is available!</span>
        </p>
        <div class="action-buttons">
          <button (click)="handleRegisterBuisness()" mat-flat-button color="primary">Register Business</button>
        </div>
      </div>

      <p *ngIf="domainCheckResult === 'UNAVAILABLE'" class="domain-result-message domain-unavailable">
        <mat-icon>error</mat-icon>
        <span>Unfortunately, the domain is not available. Try another domain name</span>
      </p>
    </div>
  </mat-card>
</main>
