<header>
  <h1>
    The payment was successful
  </h1>
</header>

<main>
  <mat-card>
    <p>Continue to view your business</p>
    <a [routerLink]="'/business/' + businessIdentifier + '/detail'">View Business</a>
  </mat-card>
</main>

