import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth-guard';
import { RegisterBusinessComponent } from './edit-business/edit-business.component';
import { HostingDetailsComponent } from './hosting/hosting-details.component';
import { InitiatePaymentComponent } from './payments/initiate-payment/initiate-payment.component';
import { PaymentSuccessComponent } from './payments/payment-success/payment-success.component';
import { AddProductComponent } from './products/add-product.component';
import { ViewProductComponent } from './products/view-product.component';
import { ViewBusinessComponent } from './view-business/view-business.component';

const routes: Routes = [
  { path: 'register', component: RegisterBusinessComponent, canActivate: [AuthGuard] },
  { path: ':identifier/detail', component: ViewBusinessComponent },
  { path: ':businessIdentifier/add-product', component: AddProductComponent, canActivate: [AuthGuard] },
  { path: ':businessIdentifier/products/:productIdentifier/edit', component: AddProductComponent },
  { path: ':businessIdentifier/products/:productIdentifier/detail', component: ViewProductComponent },
  { path: ':identifier/edit', component: RegisterBusinessComponent, canActivate: [AuthGuard] },
  { path: ':identifier/hosting', component: HostingDetailsComponent, canActivate: [AuthGuard] },
  { path: ':identifier/payment/start', component: InitiatePaymentComponent, canActivate: [AuthGuard] },
  { path: ':identifier/payment/success', component: PaymentSuccessComponent, canActivate: [AuthGuard] },
  { path: ':identifier/payment/cancel', component: PaymentSuccessComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BusinessRoutingModule { }
