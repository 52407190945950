import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceTag } from '../business/business';
import { ServiceTagsService } from '../business/service-tags.service';

@Component({
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  serviceTags: ServiceTag[] = [];
  searchForm = this.formBuilder.group({
    name: [null, Validators.required],
    service: [null, Validators.required]
  });

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private serviceTagsService: ServiceTagsService) {
  }

  ngOnInit(): void {
    this.serviceTagsService.getAllServiceTags()
      .subscribe(serviceTags => {
        this.serviceTags = serviceTags.sort((a, b) => a.name > b.name ? 1 : -1 );
      });

    this.searchForm.controls.name.valueChanges.subscribe((newValue) => {
      if (newValue?.trim().length) {
        this.searchForm.controls.service.clearValidators();
      } else {
        this.searchForm.controls.service.setValidators(Validators.required);
      }
      this.searchForm.controls.service.updateValueAndValidity({ emitEvent: false });
    });

    this.searchForm.controls.service.valueChanges.subscribe((newValue) => {
      if (newValue?.trim().length) {
        this.searchForm.controls.name.clearValidators();
      } else {
        this.searchForm.controls.name.setValidators(Validators.required);
      }
      this.searchForm.controls.name.updateValueAndValidity({ emitEvent: false });
    });
  }

  handleSearch(): void {
    const { name, service} = this.searchForm.value;
    const queryParams: any = {};
    if (name?.trim().length) {
      queryParams.name = name?.trim();
    }

    if (service?.trim().length) {
      queryParams.service = service?.trim();
    }

    this.router.navigate(['/search'], { queryParams });
  }
}
