<header>
  <h1>
    Complete Email Details
  </h1>
</header>

<main>
  <mat-card>
    <p>Set up an email account to complete the registration:</p>
    <form [formGroup]="form" (ngSubmit)="handleFormSubmit()">
      <mat-form-field>
        <mat-label>Email Address</mat-label>
        <input matInput formControlName="emailUsername" required>
        <span matSuffix>@{{business?.domain?.secondLevelDomain}}.{{business?.domain?.topLevelDomain}}</span>
        <mat-hint>Enter the first part of your email, before the @ sign</mat-hint>
        <mat-error>The email address is not right. Enter the first part of your email, before the @ sign</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Password</mat-label>
        <input type="password" matInput required formControlName="emailPassword">
      </mat-form-field>

      <div class="password-strength-container">
        <mat-label>Password Strength</mat-label>
        <mat-progress-bar [bufferValue]="65" [value]="form.controls.passwordStrength.value"></mat-progress-bar>
        <mat-error *ngIf="form.controls.emailPassword.touched && form.controls.passwordStrength.invalid">The password is not strong enough</mat-error>
      </div>
      <div>
        <button [disabled]="loading || !form.valid" mat-flat-button color="primary" type="submit">Save</button>
      </div>
      <div *ngIf="loading">
        Almost complete... This may take some time.
      </div>
    </form>
  </mat-card>
</main>

