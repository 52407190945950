import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse
} from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { TokenStoreService } from './token-store.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private tokenStore: TokenStoreService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestToSend = this.tokenStore.getToken() ?
      req.clone({ setHeaders: { Authorization: `Bearer ${this.tokenStore.getToken()}`} }) :
      req;

    return next.handle(requestToSend).pipe(catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        this.tokenStore.clearToken();
      }

      return throwError(error);
    }))
  }

}
