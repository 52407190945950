import { Component } from '@angular/core'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Review } from '../../review/review-form/review';

@Component({
  templateUrl: './review-bottom-sheet.component.html'
})
export class ReviewBottomSheetComponent {
  constructor(private _bottomSheetRef: MatBottomSheetRef<ReviewBottomSheetComponent>) {}

  handleReviewSubmit(review: Review) {
    this._bottomSheetRef.dismiss(review)
  }
}
