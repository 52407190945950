<mat-toolbar class="business-menu-toolbar mat-elevation-z2">
  <button mat-icon-button class="business-menu-button" aria-label="Business Menu button" (click)="toggleLeftMenu()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="spacer"></span>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav mode="push" [opened]="leftMenuOpen">
    <ul class="side-navigation-list">

      <li class="side-navigation-list-item">
        <a (click)="scrollToElement('header')">Home</a>
      </li>

      <li class="side-navigation-list-item">
        <a (click)="scrollToElement('addresses')">Addresses</a>
      </li>

      <li class="side-navigation-list-item">
        <a (click)="scrollToElement('contact')">Contact Details</a>
      </li>

      <li class="side-navigation-list-item">
        <a (click)="scrollToElement('socials')">Social Media</a>
      </li>

      <li class="side-navigation-list-item" *ngIf="business?.status !== 'AWAITING_PAYMENT' && (products.length || isUsersBusiness)">
        <a (click)="scrollToElement('products')">Products</a>
      </li>

      <li class="side-navigation-list-item" *ngIf="business?.status !== 'AWAITING_PAYMENT'">
        <a (click)="scrollToElement('reviews')">Reviews</a>
      </li>
    </ul>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet>
      <header id="header">
        <div class="logo-container">
          <input  accept="image/*" class="file-upload" type="file" #logoInput (change)="handleLogoFileUploaded($event)">
          <button class="logo-button" *ngIf="isUsersBusiness && !logoImage"  mat-icon-button (click)="logoInput.click()">
            <mat-icon>add_to_photos</mat-icon>
          </button>
          <img (click)="isUsersBusiness && logoInput.click()" class="logo-image" *ngIf="logoImage" [src]="logoImage.source">
        </div>

        <h1>
          {{business?.name}}
        </h1>
      </header>

      <main>
        <mat-card>
          <div class="business-information">
            <div class="images">
              <div class="main-image">
                <div class="no-images-container" *ngIf="images.length === 0 && isUsersBusiness">
                  There are currently no images for the business. Click on the button below to add pictures for the business.
                </div>

                <div *ngIf="currentImageIndex != null" class="image-change-container left">
                  <button mat-icon-button
                    (click)="moveToPreviousImage()"
                    *ngIf="currentImageIndex > 0 && images.length > 1">
                    <mat-icon>chevron_left</mat-icon>
                  </button>
                </div>
                <div  *ngIf="currentImageIndex != null" class="current-image-container">
                  <img [src]="images[currentImageIndex].source" >
                </div>
                <div *ngIf="currentImageIndex != null" class="image-change-container right">
                  <button mat-icon-button
                    (click)="moveToNextGalleryImage()"
                    *ngIf="currentImageIndex < (images.length - 1)">
                    <mat-icon>chevron_right</mat-icon>
                  </button>
                </div>
              </div>
              <div class="image-list-container">
                <input accept="image/*" class="file-upload" type="file" #imageInput (change)="handleGalleryFileUploaded($event)">
                <div class="thumbnails">
                  <img *ngFor="let image of images; let i = index" [src]="image.source" (click)="currentImageIndex = i" />
                  <button class="add-gallery-image" *ngIf="isUsersBusiness"  mat-icon-button color="primary" (click)="imageInput.click()">
                    <mat-icon>add_to_photos</mat-icon>
                  </button>
                </div>
                <div class="actions" *ngIf="isUsersBusiness">
                  <button class="add-gallery-image" *ngIf="isUsersBusiness"  mat-icon-button color="primary" (click)="imageInput.click()">
                    <mat-icon>add_to_photos</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="business-details">
              <div *ngIf="isUsersBusiness && business?.status === 'AWAITING_PAYMENT'" class="business-error">
                <mat-error>Payment for the registration is outstanding</mat-error>
                <button mat-flat-button color="primary" [routerLink]="'/business/' + business!.identifier + '/payment/start'">Make Payment</button>
              </div>
              <h2 *ngIf="business?.status !== 'AWAITING_PAYMENT'">{{business?.domain?.secondLevelDomain}}.{{business?.domain?.topLevelDomain}}</h2>

              <p>{{business?.description}}</p>
              <div *ngIf="services.length">
                <mat-chip-list aria-label="Services">
                  <mat-chip color="primary" *ngFor="let service of services">{{service.name}}</mat-chip>
                </mat-chip-list>
              </div>


              <section id="addresses" *ngIf="business">
                <h2>Addresses</h2>

                <div class="address-container">
                  <div class="address-item" *ngFor="let address of business.addresses">
                    <app-map *ngIf="address.location" [readOnly]="true" [markers]="[address.location]"></app-map>

                    <span *ngIf="address.line1">{{address.line1}}</span>
                    <span *ngIf="address.line2">{{address.line2}}</span>
                    <span *ngIf="address.line3">{{address.line3}}</span>
                    <span *ngIf="address.city">{{address.city}}</span>
                  </div>
                </div>
              </section>

              <section id="contact" *ngIf="business">
                <h2>Contact Details</h2>

                <div class="contact-numbers-container">
                  <a *ngFor="let contactNumber of business.contactNumbers" [href]="'tel:'+contactNumber.number" >{{contactNumber.number}}</a>
                </div>

                <div class="contact-numbers-container" *ngIf="business.emailAddresses?.length">
                  <a *ngFor="let emailAddress of business.emailAddresses" [href]="'mailto:'+emailAddress" >{{emailAddress}}</a>
                </div>
              </section>

              <section id="socials" *ngIf="business && business.socialProfiles?.length">
                <h2>Social Media</h2>

                <div class="social-media-container">
                  <ul class="social-media-list">
                    <li *ngFor="let socialProfile of business.socialProfiles">
                      <a *ngIf="socialProfile.type === 'FACEBOOK'" [href]="socialProfile.path" target="_blank" class="social-icon facebook fa fa-facebook"></a>
                      <a *ngIf="socialProfile.type === 'TWITTER'" [href]="socialProfile.path" target="_blank" class="social-icon twitter fa fa-twitter"></a>
                      <a *ngIf="socialProfile.type === 'INSTAGRAM'" [href]="socialProfile.path" target="_blank" class="social-icon instagram fa fa-instagram"></a>
                    </li>
                  </ul>
                </div>
              </section>
            </div>

          </div>

          <section class="action-buttons" *ngIf="business?.status !== 'AWAITING_PAYMENT'">
            <button
              *ngIf="isUsersBusiness"
              mat-flat-button color="primary"
              [routerLink]="'/business/' + business?.identifier + '/edit'">
              Edit
            </button>

            <button
              *ngIf="isUsersBusiness && hostingRegistrationRequired"
              mat-flat-button color="primary"
              [routerLink]="'/business/' + business?.identifier + '/hosting'">
              Complete Email Registration
            </button>
          </section>
        </mat-card>

        <section id="products" *ngIf="business?.status !== 'AWAITING_PAYMENT' && (products.length || isUsersBusiness)">
          <h2>Products</h2>

          <div class="action-buttons" *ngIf="isUsersBusiness">
            <a mat-stroked-button color="accent" [routerLink]="'/business/' + business?.identifier + '/add-product'">Add Product</a>
          </div>

          <div class="product-list">
            <mat-card *ngFor="let product of products" class="product-item">
              <mat-card-header>
                <mat-card-title>{{product.name}}</mat-card-title>
                <mat-card-subtitle>R {{product.price}}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                {{product.description}}
              </mat-card-content>
              <mat-card-actions>
                <button mat-button *ngIf="isUsersBusiness" [routerLink]="'/business/' + business?.identifier + '/products/' + product.identifier + '/edit'">Update</button>
                <button mat-button [routerLink]="'/business/' + business?.identifier + '/products/' + product.identifier + '/detail'">View Details</button>
              </mat-card-actions>
            </mat-card>
          </div>
        </section>

        <section id="reviews" *ngIf="business?.status !== 'AWAITING_PAYMENT'">
          <h2>Reviews</h2>

          <div class="action-buttons">
            <button mat-stroked-button color="accent" (click)="handleReviewClick()">Add Review</button>
            <button mat-stroked-button color="accent" (click)="reviewsVisible = !reviewsVisible" *ngIf="reviews.length">
              {{ reviewsVisible ? "Hide Reviews" : "View Reviews"}}
            </button>
          </div>

          <!-- <app-review-form (submitReview)="submitReview($event)"></app-review-form> -->
          <div class="review-list" *ngIf="reviewsVisible">
            <app-review-item
              *ngFor="let review of reviews"
              [review]="review"></app-review-item>
          </div>
        </section>
      </main>

    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

