import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Business } from '../business/business';

@Injectable()
export class ProfileService {
  constructor(private http: HttpClient) {}

  getUserBusinesses(): Observable<Business[]> {
    return this.http.get<{success: boolean, data: Business[]}>('/api/business')
      .pipe(map((response) => response.data), catchError((err: HttpErrorResponse) => {
        return throwError(err.error.errors[0])
      }));
  }
}
