<header>
  <h1>About Us</h1>
</header>

<main>
  <mat-card>
    <section>
      <p>
        The Bean App aims to digitise small businesses, helping potential clients and customers find them. The Bean App drives digital inclusion for all types of SMMEs by enabling them to digitise their product and service offerings through an ecommerce platform called The Bean Eco-System.
      </p>
      <p>
        The Bean Eco-System is a digital market place that enables easy and secure access for consumers to search and transact with SMMEs.
      </p>
      <p>The Team</p>
      <ul>
        <li>Mr. Louis Bezuidenhout – Product Manager</li>
        <li>Mr. Patrick Kayongo – IT Manager</li>
      </ul>
    </section>

    <section>
      <h2>Services</h2>

      <p>The Bean App aims to digitise small businesses, helping potential clients and customers find them.</p>
      <p>On The Bean App’s web portal SMMEs are able to</p>
      <ul>
        <li>Register their business’ domain name (web address)</li>
        <li>Create online profile</li>
        <li>Send emails.</li>
        <li>Conduct online transactions.</li>
      </ul>

      <h2>Pricing</h2>
      <p>Monthly Subscription fee R90 p/m</p>
    </section>

    <section>
      <h2>Contact Us</h2>

      <p>Email us at <a href="mailto:info@thebeanapp.co.za">info@thebeanapp.co.za</a></p>
      <p>
        Our business hours are as follows:
      </p>
      <ul>
        <li>Monday to Friday, 08:00 – 17:00 RSA time.</li>
        <li>Weekends: Sat to Sun, 08:00 – 13:00</li>
        <li>Please note our offices are closed during RSA public holidays.</li>
      </ul>
    </section>


  </mat-card>
</main>
