import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Business, ServiceTag } from '../business/business';
import { BusinessSearchService } from '../business/business-search.service';
import { ServiceTagsService } from '../business/service-tags.service';

@Component({
  styleUrls: ['./search.component.scss'],
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit {
  serviceTags: ServiceTag[] = [];
  searchResults?: Business[];
  radius = 20000;
  searchForm = this.formBuilder.group({
    name: [null, Validators.required],
    service: [null, Validators.required]
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private businessSearchService: BusinessSearchService,
    private serviceTagsService: ServiceTagsService) {
  }

  ngOnInit(): void {
    this.serviceTagsService.getAllServiceTags()
      .subscribe(serviceTags => {
        this.serviceTags = serviceTags.sort((a, b) => a.name > b.name ? 1 : -1 );
      });

    this.searchForm.controls.name.valueChanges.subscribe((newValue) => {
      if (newValue?.trim().length) {
        this.searchForm.controls.service.clearValidators();
      } else {
        this.searchForm.controls.service.setValidators(Validators.required);
      }
      this.searchForm.controls.service.updateValueAndValidity({ emitEvent: false });
    });

    this.searchForm.controls.service.valueChanges.subscribe((newValue) => {
      if (newValue?.trim().length) {
        this.searchForm.controls.name.clearValidators();
      } else {
        this.searchForm.controls.name.setValidators(Validators.required);
      }
      this.searchForm.controls.name.updateValueAndValidity({ emitEvent: false });
    });

    this.activatedRoute.queryParams.pipe(first()).subscribe((queryParams) => {
      const { name, service } = queryParams;
      this.searchForm.controls.name.setValue(name);
      this.searchForm.controls.service.setValue(service);
      this.searchForBusiness();
    });
  }

  searchForBusiness(position?: {latitude: number; longitude: number; withinDistanceMetres: number;}): void {
    const { name, service } = this.searchForm.value;
    if (name || service || position) {
      this.businessSearchService.search({ name, service, location: position })
        .subscribe((results) => {
          this.searchResults = results;
        });
    }
  }

  searchForBusinessNearMe(): void {
    navigator.geolocation.getCurrentPosition(
      (currentPosition) => {
        this.searchForBusiness({
          latitude: currentPosition.coords.latitude,
          longitude: currentPosition.coords.longitude,
          withinDistanceMetres: this.radius,
        });
      },
      (error) => {
        this.searchForBusiness();
      }
    )
  }
}
