import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Product } from './product';
import { ProductService } from './product.service';

@Component({
  template: `
    <header><h1>{{this.productIdentifier ? 'Update Product' : 'Add Product'}}</h1></header>

    <main>
      <a [routerLink]="'/business/' + businessIdentifier + '/detail'">Back</a>
      <mat-card>
        <form [formGroup]="formGroup" (ngSubmit)="createProduct()">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Price</mat-label>
            <span matPrefix class="currency-symbol">R</span>
            <input class="currency-input" matInput type="number" min="1" step="any" formControlName="price" />
          </mat-form-field>
          <div>
            <button [disabled]="!formGroup.valid" mat-raised-button color="primary">Save</button>
          </div>
        </form>
      </mat-card>
    </main>
  `,
  styles: [`
    form {
      max-width: 30em;
      width: 100%;
    }

    mat-form-field {
      width: 100%;
    }

    .currency-symbol {
      margin-right: 0.5em;
    }

    .currency-input {
      text-align: right;
    }
  `]
})
export class AddProductComponent implements OnInit {
  businessIdentifier?: string;
  productIdentifier?: string;
  existingProduct?: Product|null;
  formGroup = this.formBuilder.group({
    name: ['', Validators.required],
    description: [null, Validators.required],
    price: [null, Validators.required],
  });
  images: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private productService: ProductService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.businessIdentifier = params.businessIdentifier;
      this.productIdentifier = params.productIdentifier;

      if (this.businessIdentifier && this.productIdentifier) {
        this.productService.getProduct(this.businessIdentifier, this.productIdentifier)
          .pipe(first())
          .subscribe(product => {
            this.existingProduct = product;
            this.formGroup.patchValue({...product});
          });
      }
    });
  }

  createProduct(): void {
    if (this.businessIdentifier) {
      if (this.existingProduct?.identifier) {
        this.productService.updateProduct(
          this.businessIdentifier, this.existingProduct.identifier, {...this.existingProduct, ...this.formGroup.value})
          .subscribe(() => {
            this.router.navigate([`/business/${this.businessIdentifier}/detail`]);
          });
      } else {
        this.productService.createProduct(this.businessIdentifier, this.formGroup.value)
          .subscribe(() => {
            this.router.navigate([`/business/${this.businessIdentifier}/detail`]);
          });
      }
    }
  }
}
