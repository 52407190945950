import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImageDetail } from '../business';
import { Product } from './product';

@Injectable()
export class ProductService {
  constructor(private httpClient: HttpClient) {}

  createProduct(businessIdentifier: string, product: Product): Observable<unknown> {
    return this.httpClient.post(`/api/business/${businessIdentifier}/product`, product);
  }

  updateProduct(businessIdentifier: string, productIdentfier: string, product: Product): Observable<unknown> {
    return this.httpClient.put(`/api/business/${businessIdentifier}/product/${productIdentfier}`, product);
  }

  getProductsForBusiness(businessIdentifier: string): Observable<Product[]> {
    return this.httpClient.get<{ success: boolean, data: Product[]}>(`/api/business/${businessIdentifier}/product`)
      .pipe(map(m => m.data));
  }

  getProduct(businessIdentifier: string, productIdentfier: string): Observable<Product|null> {
    return this.httpClient.get<{ success: boolean, data: Product|null}>(`/api/business/${businessIdentifier}/product/${productIdentfier}`)
      .pipe(map(m => m.data));
  }

  getImagesForProduct(businessIdentifier: string, productIdentfier: string): Observable<ImageDetail[]> {
    return this.httpClient.get<{ success: boolean, images: ImageDetail[] }>(`/api/business/${businessIdentifier}/product/${productIdentfier}/images`)
      .pipe(map(res => res.images));
  }

  uploadImage(businessIdentifier: string, productIdentfier: string, filename: string, image: File): Observable<boolean> {
    const formData = new FormData();
    formData.append('filename', filename);
    formData.append('image', image);

    return this.httpClient.post<{ success: boolean }>(`/api/business/${businessIdentifier}/product/${productIdentfier}/images`, formData)
      .pipe(map(res => res.success));
  }
}
