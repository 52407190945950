import { Injectable } from '@angular/core';
import { DomainCheckStatus } from './domains.service';

export interface DomainCrossover {
  domain: {
    topLevelDomain: string,
    secondLevelDomain: string
  };
  domainCheckStatus: DomainCheckStatus | null;
}

@Injectable({ providedIn: 'root' })
export class DomainCrossoverService {
  crossover?: DomainCrossover | null = null;
}
