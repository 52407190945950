import { Component, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { parsePhoneNumber, AsYouType } from 'libphonenumber-js';
import { phoneNumber } from 'src/app/common-components/validators/phone-number.validator';

@Component({
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ContactNumberComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ContactNumberComponent), multi: true },
  ],
  selector: 'app-business-contact-number',
  styleUrls: ['./contact-number.component.scss'],
  templateUrl: './contact-number.component.html',
})
export class ContactNumberComponent implements ControlValueAccessor, Validator {
  form = this.formBuilder.group({
    number: [null, [Validators.required, phoneNumber]],
  });

  onTouched = () => {};

  constructor(private formBuilder: FormBuilder) {}

  validate(control: AbstractControl): ValidationErrors | null {
    return this.form.controls.number.errors;
  }

  writeValue(obj: any): void {
    let existingPhoneNumebr = obj?.number;
    if (existingPhoneNumebr) {
      const parsedPhoneNumber = parsePhoneNumber(existingPhoneNumebr, 'ZA');
      existingPhoneNumebr = parsedPhoneNumber.format('NATIONAL', { fromCountry: 'ZA', humanReadable: true });
    }

    this.form.patchValue({ number: existingPhoneNumebr });
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe((formValues) => {
      const newPhoneNumber = formValues.number;
      if (newPhoneNumber?.trim().length) {
        this.form.controls.number.setValue(new AsYouType('ZA').input(newPhoneNumber), { emitEvent: false });
        try {
          const parsedPhoneNumber = parsePhoneNumber(newPhoneNumber, 'ZA');
          if (parsedPhoneNumber) {
            fn({ number: parsedPhoneNumber.format('E.164', { fromCountry: 'ZA' }) });
            return;
          }
        } catch (error) {
        }
      }

      fn({ number: newPhoneNumber });
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
