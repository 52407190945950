import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HostingDetailsService {

  constructor(private httpClient: HttpClient) {
  }

  sendHostingDetails(businessIdentifier: string, details: { emailUsername: string, emailPassword: string }): Observable<unknown> {
    return this.httpClient.post<{success: boolean}>(`/api/business/${businessIdentifier}/hosting`, details)
      .pipe(catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 409:
            return throwError(new Error('The email has already been registered'));
          case 400:
            return throwError(new Error('The domain has already been registered'));
          case 500:
            return throwError(new Error('Something went wrong, please try again later'));
          default:
            return throwError(new Error('Something went wrong, please try again later'));
        }
      }));
  }

  getPasswordStrength(businessIdentifier: string, password: string): Observable<number> {
    return this.httpClient.post<{success: boolean, data: { strength: number}}>(
        `/api/business/${businessIdentifier}/hosting/password-strength`, { password })
        .pipe(map(r => r.data.strength ));
  }
}
