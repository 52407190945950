import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessImageType, ImageDetail } from './business';

@Injectable()
export class BusinessImageService {

  constructor(private httpClient: HttpClient) {}

  getImagesForBusiness(businessIdentifier: string): Observable<ImageDetail[]> {
    return this.httpClient.get<{ success: boolean, images:ImageDetail[] }>(`/api/business/${businessIdentifier}/images`)
      .pipe(map(res => res.images))
  }

  uploadImageForBusiness(businessIdentifier: string, filename: string, imageType: BusinessImageType, image: File): Observable<boolean> {
    const formData = new FormData()
    formData.append('filename', filename)
    formData.append('image', image)
    formData.append('imageType', imageType)

    return this.httpClient.post<{ success: boolean }>(`/api/business/${businessIdentifier}/images`, formData)
      .pipe(map(res => res.success))
  }
}
