<main>
  <div class="feature-image-background">
    <mat-card class="information-piece">
      <h1>Let us get your business online</h1>

      <p>The Bean App aims to digitise small businesses for as little as R90.00 p/m, helping potential clients and customers find them.</p>
      <div class="actions">
        <button mat-flat-button color="primary" [routerLink]="'domain-search'">Domain Search</button>
      </div>
    </mat-card>
  </div>

  <mat-card class="mobile-feature-image">
    <img src="assets/img/we-open.jpg">
  </mat-card>
</main>
