import { Component, forwardRef, Input } from '@angular/core'
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StarRatingComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => StarRatingComponent), multi: true },
  ],
})
export class StarRatingComponent implements ControlValueAccessor, Validator {

  currentRating?: number|null
  hoverRating?: number|null
  ratings = [1, 2, 3, 4, 5]
  isDisabled = false

  @Input() set rating(rating: number) {
    this.currentRating = rating
  }

  @Input() set disabled(isDisabled: boolean) {
    this.isDisabled = isDisabled
  }

  get ratingToShow () {
    return this.hoverRating || this.currentRating || 0;
  }

  onChange = (rating: number) => {}
  onTouched = () => {}

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  writeValue(value: any): void {
    this.currentRating = value
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled
  }

  setHoverRating(rating: number) {
    if (this.isDisabled) {
      return
    }

    this.hoverRating = rating;
  }

  clearHoverRating() {
    if (this.isDisabled) {
      return
    }

    this.hoverRating = null;
  }

  iconForRating(rating: number) {
    if (this.ratingToShow >= rating) {
      return 'star'
    }

    if (this.ratingToShow > (rating - 1)) {
      return 'star_half'
    }

    return 'star_outline'
  }

  updateCurrentRating(rating: number) {
    if (this.isDisabled) {
      return
    }

    this.currentRating = rating;
    this.onChange(this.currentRating)
    this.onTouched()
  }
}
