import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Business } from './business';

@Injectable()
export class BusinessRetrievalService {
  constructor(private http: HttpClient) {}

  retrieveBusiness(identifier: string): Observable<Business> {
    return this.http.get<{ success: boolean, data: Business}>('/api/business/' + identifier)
      .pipe(map((response) => response.data), catchError((err: HttpErrorResponse) => {
        return throwError(err.status === 404 ? 'The business does not exist' : 'Something went wrong')
      }));
  }
}
