import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Payment } from '../../business';
import { BusinessPaymentService } from '../business-payment.service';

@Component({
  templateUrl: './initiate-payment.component.html'
})
export class InitiatePaymentComponent implements OnInit {
  outstandingPayment?: Payment;
  merchantId?: string;
  merchantKey?: string;
  paymentURL?: string;
  businessIdentifier?: string;
  signature?: string;
  billingStartDate?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private businessPaymentService: BusinessPaymentService) {
  }

  get notifyURL(): string {
    return `${window.location.origin}/api/business/${this.businessIdentifier}/payment`;
  }

  get cancelURL(): string {
    return `${window.location.origin}/business/${this.businessIdentifier}/payment/cancel`;
  }

  get successURL(): string {
    return `${window.location.origin}/business/${this.businessIdentifier}/payment/success`;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      this.businessIdentifier = params.identifier;
      this.businessPaymentService.getOutstandingPayment(params.identifier)
        .subscribe((result) => {
          this.outstandingPayment = result.payment;
          this.merchantId = result.configuration.merchantId;
          this.merchantKey = result.configuration.merchantKey;
          this.paymentURL = result.configuration.paymentURL;
          this.signature = result.signature;
          this.billingStartDate = result.billingStartDate;
        });
    });
  }
}
