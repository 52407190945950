import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { UserAccountSignupDetails } from './registration-details'

@Injectable()
export class RegistrationService {
  constructor(private http: HttpClient, private angularFireAuth: AngularFireAuth) {}

  registerUser(details: UserAccountSignupDetails): Observable<unknown> {
    const subject = new Subject();
    this.angularFireAuth.createUserWithEmailAndPassword(details.email, details.password)
      .then((userCredential) => {
        this.http.post('/api/account', {
          firstName: details.firstName,
          lastName: details.lastName,
          email: details.email,
          phoneNumber: details.phoneNumber,
          identifier: userCredential.user?.uid,
          password: details.password
        })
          .subscribe(() => {
            subject.next();
            subject.complete();
          }, (error) => {
            subject.error(error);
          });
      })
      .catch((error) => {
        let errorMesssage = error.message;
        if (error.code === 'auth/email-already-in-use') {
          errorMesssage = 'ACCOUNT_EXISTS';
        }
        subject.error(errorMesssage);
      });

    return subject;
  }
}
