
<div class="review-item">
  <div class="name-container">
    <mat-icon>person</mat-icon>
    <span class="reviewer-name">{{review?.reviewerName}}</span>
  </div>
  <div class="rating-container">
    <app-star-rating [rating]="review?.rating || 0" [disabled]="true"></app-star-rating>
    <span class="review-summary">{{review?.summary}}</span>
  </div>
  <p class="review-date">Reviewed on {{review?.dateAdded | date}}</p>
  <p class="review-description">{{review?.description}}</p>

</div>
