import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ServiceTag } from '../business';
import { BusinessRegistrationService } from '../business-registration.service';
import { BusinessRetrievalService } from '../business-retrieval.service';
import { BusinessUpdateService } from '../business-update.service';
import { DomainCrossoverService } from '../domain-crosssover.service';
import { DomainCheckStatus, DomainsService } from '../domains.service';
import { ServiceTagsService } from '../service-tags.service';

@Component({
  styleUrls: ['./edit-business.component.scss'],
  templateUrl: './edit-business.component.html',
})
export class RegisterBusinessComponent implements OnInit {
  identifier?: string;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  form = this.formBuilder.group({
    name: ['', Validators.required],
    domain: ['', Validators.required],
    description: ['', Validators.required],
    addresses: this.formBuilder.array([]),
    contactNumbers: this.formBuilder.array([new FormControl()]),
    socialProfiles: this.formBuilder.array([]),
  });

  domainCheckStatus: DomainCheckStatus | null = null;
  checkingAvailability = false;
  allServices: ServiceTag[] = [];
  businessServices: ServiceTag[] = [];
  @ViewChild('servicesInput') servicesInput?: ElementRef<HTMLInputElement>;

  get addresses() {
    return this.form.get('addresses') as FormArray;
  }

  get contactNumbers() {
    return this.form.get('contactNumbers') as FormArray;
  }

  get socialProfiles() {
    return this.form.get('socialProfiles') as FormArray;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private domainService: DomainsService,
    private businessRegistrationService: BusinessRegistrationService,
    private businessRetrievalService: BusinessRetrievalService,
    private businessUpdateService: BusinessUpdateService,
    private domainCrossoverService: DomainCrossoverService,
    private serviceTagService: ServiceTagsService) {
  }

  ngOnInit(): void {
    this.refreshServiceTags();

    this.activatedRoute.params.subscribe((params) => {
      this.identifier = params.identifier;
      if (this.identifier) {
        this.loadTagsForBusiness();

        this.businessRetrievalService.retrieveBusiness(this.identifier)
          .subscribe((business) => {
            const formValues = {
              ...business,
              domain: business.domain.secondLevelDomain
            };

            this.form.patchValue(formValues);
            this.form.controls.domain.disable();
            this.addresses.clear();
            business.addresses.forEach((address) => this.addresses.push(new FormControl(address)));

            this.contactNumbers.clear();
            business.contactNumbers.forEach((contact) => this.contactNumbers.push(new FormControl(contact)));

            this.socialProfiles.clear();
            business.socialProfiles.forEach((socialProfile) => this.socialProfiles.push(new FormControl(socialProfile)));
          });
      } else {
        this.addresses.push(new FormControl())
        const crossOver = this.domainCrossoverService.crossover;
        if (crossOver) {
          this.form.controls.domain.setValue(crossOver.domain.secondLevelDomain);
          this.domainCheckStatus = crossOver.domainCheckStatus;
          this.domainCrossoverService.crossover = null;
        }
      }
    });

    this.form.controls.domain.valueChanges.subscribe(() => {
      this.domainCheckStatus = null;
      if (this.form.controls.domain.hasError('domainUnavailable')) {
        const errors = this.form.controls.domain.errors;
        delete errors!.domainUnavailable;
        this.form.controls.domain.setErrors(errors);
      }
    });
  }

  addAddress(): void {
    this.addresses.controls.push(new FormControl());
  }

  addContactNumber(): void {
    this.contactNumbers.controls.push(new FormControl());
  }

  hasSocialProfile(type: string): boolean {
    return this.socialProfiles.controls.findIndex(c => c.value?.type === type) > -1;
  }

  toggleSocialProfile(type: string): void {
    const existingIndex = this.socialProfiles.controls.findIndex(c => c.value?.type === type);
    if (existingIndex > -1) {
      this.socialProfiles.removeAt(existingIndex);
    } else {
      this.socialProfiles.push(new FormControl({ type }));
    }
  }

  addSocialProfile(type: string): void {
    this.contactNumbers.controls.push(new FormControl({ type }));
  }

  submitForm(): void {
    const formValues = this.form.value;
    const updatedIdentifier = (formValues.name as string).toLowerCase().split(' ').join('-');
    const domain: string = formValues.domain;
    const businessDetails = {
      ...formValues,
      identifier: updatedIdentifier,
      domain: {
        topLevelDomain: 'co.za',
        secondLevelDomain: domain,
      }
    };

    if (this.identifier) {
      this.businessUpdateService.updateBusiness(this.identifier, businessDetails)
        .subscribe(() => {
          this.saveTagForBusiness(updatedIdentifier);
          this.handleSuccess(updatedIdentifier);
        });
    } else {
      if (this.domainCheckStatus === DomainCheckStatus.AVAILABLE) {
        this.registerNewBusiness(updatedIdentifier, businessDetails);
        return;
      }

      this.domainService.getDomainAvailability(domain, 'co.za')
        .pipe(first())
        .subscribe((status) => {
          this.handleDomainCheckResponse(status);
          if (status === DomainCheckStatus.UNAVAILABLE) {
            return;
          }

          this.registerNewBusiness(updatedIdentifier, businessDetails);
        });
    }
  }

  registerNewBusiness(identifier: string, businessDetails: any): void {
    this.businessRegistrationService.registerBusiness(businessDetails)
          .subscribe(() => {
            this.saveTagForBusiness(identifier);
            this.router.navigate([`/business/${identifier}/payment/start`]);
          });
  }

  handleSuccess(identifier: string): void {
    this.router.navigate([`/business/${identifier}/detail`]);
  }

  checkForDomainAvailability(): void {
    this.checkingAvailability = true;
    const secondLevelDomain = this.form.controls.domain.value;
    this.domainService.getDomainAvailability(secondLevelDomain, 'co.za')
      .pipe(first())
      .subscribe((status) => this.handleDomainCheckResponse(status));
  }

  handleDomainCheckResponse(status: DomainCheckStatus): void {
    this.checkingAvailability = false;
    this.domainCheckStatus = status;
    if (status === DomainCheckStatus.UNAVAILABLE) {
      this.form.controls.domain.setErrors({domainUnavailable: true});
    } else {
      this.form.controls.domain.setErrors(null);
    }
  }

  refreshServiceTags(): void {
    this.serviceTagService.getAllServiceTags().pipe(first()).subscribe((tags) => {
      this.allServices = tags;
    });
  }

  loadTagsForBusiness(): void {
    if (this.identifier) {
      this.serviceTagService.getServiceTagsForBusiness(this.identifier).pipe(first()).subscribe((tags) => {
        this.businessServices = tags;
      });
    }
  }

  saveTagForBusiness(businesssIdentifier: string): void {
    this.serviceTagService.getServiceTagsForBusiness(businesssIdentifier).subscribe((existingTags) => {
      const tagsToSave = this.businessServices.filter((f) => existingTags.map(t => t.name).indexOf(f.name) < 0);
      const tagsToDelete = existingTags.filter(f => this.businessServices.map(t => t.name).indexOf(f.name) < 0);
      tagsToSave.forEach((tag) => this.serviceTagService.createServiceTagForBusiness(businesssIdentifier, tag).subscribe());
      tagsToDelete.forEach((tag) => this.serviceTagService.deleteServiceTagForBusiness(businesssIdentifier, tag).subscribe());
    });
  }

  serviceSelected(event: MatAutocompleteSelectedEvent): void {
    this.addService(event.option.viewValue);
  }

  handleChipAddValue(event: MatChipInputEvent): void {
    this.addService(event.value);
  }

  addService(value: string): void {
    const tagToSave = this.allServices.find(f => f.name.toLowerCase() === value.toLowerCase()) || { name: value };

    const businessServicesIndex = this.businessServices.findIndex(f => f.name.toLowerCase() === tagToSave.name.toLowerCase());
    if (businessServicesIndex < 0) {
      this.businessServices.push(tagToSave);
    }

    this.servicesInput!!.nativeElement.value = '';
  }

  removeService(serviceTag: ServiceTag): void {
    const businessServicesIndex = this.businessServices.findIndex(f => f.name.toLowerCase() === serviceTag.name.toLowerCase());
    if (businessServicesIndex >= 0) {
      this.businessServices.splice(businessServicesIndex, 1);
    }
  }
}
