import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as mapboxgl from 'mapbox-gl';
import { ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Adddress } from '../../business/business';

@Component({
  selector: 'app-map',
  styles: [`
    .map-container {
      width: 100%;
      height: 100%;
      /* position: absolute; */
    }

    :host {
      display: block;
    }
  `
  ],
  template: `
    <div id="myMap" class="map-container"></div>
  `
})
export class MapComponent implements OnInit {
  @Input() readOnly = false;
  @Output() addressSelect: EventEmitter<Adddress> = new EventEmitter();
  @Output() markerMoved: EventEmitter<{ latitude: number, longitude: number}> = new EventEmitter();

  map?: mapboxgl.Map;
  mapMarkers: mapboxgl.Marker[] = [];
  private markerCoordinates = new ReplaySubject<{ latitude: number, longitude: number}[]>(1);
  private initialCoordinatesLoaded = false;

  constructor() {
    (mapboxgl as any).accessToken = environment.mapbox.accessToken;
  }

  @Input()
  set markers(m: { latitude: number, longitude: number}[]) {
    this.markerCoordinates.next(m);
  }

  ngOnInit(): void {
    this.map = new mapboxgl.Map({
      container: 'myMap',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 10,
      center: [28.0928086, -26.1032096]
    });
    this.map.addControl(new mapboxgl.AttributionControl());
    if (!this.readOnly) {
      this.map.addControl(new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl as any
      }).on('result', event => {
        const selectedAddress: Adddress = {
          province: event?.result?.context?.filter((f: any) => f.id?.indexOf('region') > -1)[0]?.short_code,
          city: event?.result?.context?.filter((f: any) => f.id?.indexOf('place') > -1)[0]?.text,
          line3: event?.result?.context?.filter((f: any) => f.id?.indexOf('locality') > -1)[0]?.text,
          line2: event?.result?.context?.filter((f: any) => f.id?.indexOf('neighborhood') > -1)[0]?.text,
          line1: [event?.result?.address, event?.result?.text].join(' '),
          postalCode: event?.result?.context?.filter((f: any) => f.id?.indexOf('postcode') > -1)[0]?.text,
          location: {
            latitude: event.result?.center[1],
            longitude: event.result?.center[0]
          }
        };
        this.addressSelect.emit(selectedAddress);
      }));

      this.map.on('click', (e) => {
        this.markerMoved.emit({ longitude: e.lngLat.lng, latitude: e.lngLat.lat});
      });
    }

    this.markerCoordinates.subscribe(coordinates => {
      if (this.map && coordinates) {
        this.mapMarkers.forEach(marker => marker.remove());
        this.mapMarkers = [];
        const validCoordinates = coordinates.filter(f => f);
        validCoordinates.forEach(coordinate => {
          const marker = new mapboxgl.Marker()
            .setLngLat([coordinate.longitude, coordinate.latitude])
            .setDraggable(!this.readOnly)
            .addTo(this.map as any);
          this.mapMarkers.push(marker);
          marker.on('dragend', () => {
            this.markerMoved.emit({ longitude: marker.getLngLat().lng, latitude: marker.getLngLat().lat });
          });
        });

        if (validCoordinates.length === 1 && !this.initialCoordinatesLoaded) {
          this.map?.setCenter([validCoordinates[0].longitude, validCoordinates[0].latitude]);
          this.map?.setZoom(13);
          this.initialCoordinatesLoaded = true;
        }
      }
    });
  }

}
