import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Business } from './business';

export interface SearchParameters {
  service?: string;
  name?: string;
  domain?: {
    topLevelDomain: string;
    secondLevelDomain: string;
  };
  location?: {
    latitude: number;
    longitude: number;
    withinDistanceMetres: number;
  }
}

@Injectable({ providedIn: 'root' })
export class BusinessSearchService {
  constructor(private http: HttpClient) {}

  search(searchParameters: SearchParameters): Observable<Business[]> {
    const params: any = {};
    if (searchParameters.name?.length) {
      params.name = searchParameters.name;
    }

    if (searchParameters.service?.length) {
      params.service = searchParameters.service;
    }

    if (searchParameters.domain?.secondLevelDomain?.length) {
      params.sld = searchParameters.domain.secondLevelDomain;
    }

    if (searchParameters.domain?.topLevelDomain?.length) {
      params.tld = searchParameters.domain.topLevelDomain;
    }

    if (searchParameters.location?.latitude && searchParameters.location?.longitude) {
      params.location = `${searchParameters.location?.latitude},${searchParameters.location?.longitude}`;
    }

    if (searchParameters.location?.withinDistanceMetres) {
      params.radius = `${searchParameters.location?.withinDistanceMetres}`;
    }

    return this.http.get<{ success: boolean, results: Business[]}>('/api/business/search', { params })
      .pipe(map((response) => response.results));
  }
}
