export interface Business {
  identifier: string;
  status: BusinessStatus,
  domain: {
    topLevelDomain: string,
    secondLevelDomain: string
  };
  name: string;
  description: string;
  addresses: Adddress[];
  contactNumbers: ContactNumber[];
  socialProfiles: SocialProfile[];
  emailAddresses?: string[];
}

export enum BusinessStatus {
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  AWAITING_DOMAIN_REGISTRATION = 'AWAITING_DOMAIN_REGISTRATION',
  ACTIVE = 'ACTIVE',
  AWAITING_HOSTING_REGISTRATION = 'AWAITING_HOSTING_REGISTRATION'
}

export interface Adddress {
  identifier?: string;
  line1: string;
  line2: string;
  line3: string;
  city: string;
  province: string;
  postalCode?: string;
  location?: {
    longitude: number;
    latitude: number;
  }
}

export interface ContactNumber {
  number: string;
}

export enum SocialProfileType {
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
}

export interface SocialProfile {
  type: SocialProfileType;
  path: string;
}

export interface Payment {
  amount: number
  description: string
}

export enum BusinessImageType {
  GALLERY = 'GALLERY',
  LOGO = 'LOGO'
}

export interface ImageDetail {
  filename: string
  mimeType: string
  imageType: BusinessImageType
}

export interface ServiceTag {
  name: string
}
