import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { BusinessPaymentService } from './business-payment.service';
import { InitiatePaymentComponent } from './initiate-payment/initiate-payment.component';
import { PaymentCancelComponent } from './payment-cancel/payment-cancel.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';

@NgModule({
  declarations: [
    InitiatePaymentComponent,
    PaymentSuccessComponent,
    PaymentCancelComponent,
  ],
  exports: [
    InitiatePaymentComponent,
    PaymentSuccessComponent,
    PaymentCancelComponent,
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatCardModule,
  ],
  providers: [
    BusinessPaymentService
  ]
})
export class PaymentModule {}
