import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  templateUrl: './payment-success.component.html'
})
export class PaymentSuccessComponent implements OnInit {
  businessIdentifier = '';

  constructor(
    private activatedRoute: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      this.businessIdentifier = params.identifier;
      this.router.navigate([`/business/${this.businessIdentifier}/hosting`]);
    });
  }
}
