import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { debounce, debounceTime, first } from 'rxjs/operators';
import { Business, BusinessStatus } from '../business';
import { BusinessRetrievalService } from '../business-retrieval.service';
import { HostingDetailsService } from './hosting-details.service';

@Component({
  styleUrls: ['./hosting-details.component.scss'],
  templateUrl: './hosting-details.component.html',
})
export class HostingDetailsComponent implements OnInit {
  businessIdentifier?: string;
  business?: Business;
  loading = false;

  form = this.formBuilder.group({
    emailUsername: [null, [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
    emailPassword: [null, Validators.required],
    passwordStrength: [0, Validators.min(65)]
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private matSnackbar: MatSnackBar,
    private router: Router,
    private formBuilder: FormBuilder,
    private businessRetrievalService: BusinessRetrievalService,
    private hostingDetailsService: HostingDetailsService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      this.businessIdentifier = params.identifier;
      if (this.businessIdentifier) {
        this.businessRetrievalService.retrieveBusiness(this.businessIdentifier).pipe(first()).subscribe((business) => {
          this.business = business;
          if (business.status !== BusinessStatus.AWAITING_HOSTING_REGISTRATION) {
            this.router.navigate([`/business/${this.businessIdentifier}/detail`]);
          }
        });
      }
    });


    this.form.controls.emailPassword.valueChanges.pipe(debounceTime(300)).subscribe(password => {
      if (!this.businessIdentifier) {
        return;
      }

      this.hostingDetailsService.getPasswordStrength(this.businessIdentifier, password)
        .pipe(first())
        .subscribe(strength => {
          this.form.controls.passwordStrength.setValue(strength);
        });
    });
  }

  handleFormSubmit(): void {
    if (this.businessIdentifier && !this.loading) {
      this.loading = true;
      this.hostingDetailsService.sendHostingDetails(this.businessIdentifier, this.form.value)
        .subscribe(() => {
          this.loading = false;
          this.router.navigate([`/business/${this.businessIdentifier}/detail`]);
        },
        (error) => {
          this.loading = false;
          this.matSnackbar.open(error.message, 'Close', { duration: 4000 });
        });
    }

  }

}
